import React, { useState } from 'react';
import styled from 'styled-components';

const ServicesSection = styled.section`
  text-align: center;
`;

const ServicesTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const TitleUnderline = styled.div`
  height: 2px;
  width: 100px;
  background-color: ${props => props.theme.colors.primary};
  margin: 0 auto 2rem;
`;

const ServiceGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 2rem;
  justify-content: center;
`;

const ServiceCard = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-5px);
  }
`;

const ServiceImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const ServiceContent = styled.div`
  padding: 1.5rem;
`;

const ServiceTitle = styled.h3`
  margin-bottom: 0.5rem;
  color: ${props => props.theme.colors.primary};
`;

const ServiceDescription = styled.p`
  margin-bottom: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ViewMoreButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  max-width: 600px;
  max-height: 80vh;
  overflow-y: auto;
`;

const ModalTitle = styled.h2`
  color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
`;

const ModalText = styled.p`
  margin-bottom: 1rem;
  line-height: 1.6;
`;

const ModalList = styled.ul`
  margin-bottom: 1rem;
  padding-left: 1.5rem;
`;

const ModalListItem = styled.li`
  margin-bottom: 0.5rem;
`;

const CloseButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
`;

const services = [
  {
    title: "3-D Elevation Drawing",
    image: "https://res.cloudinary.com/dwbiisgio/image/upload/v1729107841/SAVE_20240810_093551_pioftd.jpg",
    description: "3D Elevation drawing is the three dimensional representation of a certain building or a space. It specifically comprises of the exterior design of any structure like any house, restaurant, commercial building, parking space or any residential building.",
    fullDescription: "3D Elevation drawing is the three dimensional representation of a certain building or a space. It specifically comprises of the exterior design of any structure like any house, restaurant, commercial building, parking space or any residential building. The elevated visualisation that we get through a 3D drawing holds immense importance in linking the fields of construction and architecture. We, through our 3D elevation drawings always hold the truest intention for the maximum clarity of of any space, from different angles and heights. This helps our clients gain a much broader and futuristic insight on the aesthetics of the building , may it be residential or a commercial space. The lights, decorative structures, terrace designs and some of the complicated creative visions can be easily got through our 3D elevation drawings. 3D elevation drawings also pave the way for a better and efficient communication between the client and the design team. Clients often remain confused of the future of their projects as to how it may look after completion or what may be their scheme of budgeting the project. The post-designing process also undergo certain changed as per the requirement of our clients. 3D elevation drawings has also been a boon for our circle of property dealers, real estate agents and developers. The elevation drawing and their aesthetics has been the magnetic force to pull the in-demand clients towards their projects and has also contributed to their hike in project demand. With the aesthetic factor of the market rising, we Elegant-E-Space as a team has been providing our clients with end-to-end services and highly aesthetic 3D elevation drawings for the last half a decade. So, here we are, hoping to provide you further with better quality of designs and elevation drawings ar per your requirements."
  },
  {
    title: "Interior Decoration",
    image: "https://res.cloudinary.com/dwbiisgio/image/upload/v1729109181/SAVE_20240812_241600_qvetfi.jpg",
    description: "The process of uplifting the aesthetic appeal of a space using various elements, creating a functional and beautiful environment.",
    fullDescription: "The process of uplifting the aesthetic appeal of a space using various elements, creating a functional and beautiful environment. Key Elements: 1. Color: Paint, fabrics, furniture finishes 2. Lighting: Natural, artificial, ambiance 3. Textures: Fabrics, materials, patterns 4. Furniture: Style, arrangement, functionality 5. Accessories: Rugs, artwork, decorative items 6. Patterns: Fabrics, wallpaper, flooring Interior Decoration Styles: 1. Modern 2. Traditional 3. Minimalist 4. Rustic 5. Coastal 6. Bohemian Goals: 1. Reflect personal taste 2. Create ambiance 3. Enhance functionality 4. Boost mood At Elegant E Space, interior decoration services can transform your space into a stunning reflection of your style. We as a team are always aiming towards perfection in our pursuit of decorating your space (may it be a commercial, domestic or for recreational purposes). Please feel free for a consultation and site visit at your convenience."
  },
  {
    title: "Vastu Consultation",
    image: "https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/tc8qkfdim1o3gpndmj5x.jpg",
    description: "Vastu Shastra is an ancient Indian philosophy that combines architecture, astrology, and spirituality to create harmonious and balanced living and working spaces.",
    fullDescription: "Vastu Shastra is an ancient Indian philosophy that combines architecture, astrology, and spirituality to create harmonious and balanced living and working spaces. It's based on the concept that buildings and structures should be designed to align with the natural energies surrounding us, enhancing well-being, prosperity, and happiness. Key principles include: 1. Orientation (direction) 2. Layout (space planning) 3. Elements (earth, water, fire, air, space) 4. Colors 5. Geometry Vastu Shastra aims to balance and harness positive energies (e.g., sunlight, wind) while minimizing negative influences. So Elegant E Space is helping everyone unlock harmony, prosperity, and happiness in your life. We are providing: - Expert Vastu guidance for home & office - Personalized solutions for success - Improve relationships, health & wealth We are providing: 1. Vastu-based design packages 2. Personalized Vastu consultations 3. Vastu tips and advice for clients Book Your Consultation Now by Contacting us. We are running a limited time offer Get 20% off your first consultation!"
  }
];

function Services() {
  const [selectedService, setSelectedService] = useState(null);

  const formatModalContent = (content) => {
    const lines = content.split('. ');
    return lines.map((line, index) => {
      const boldPattern = /(\d+\.\s|\w+:)/g;
      const parts = line.split(boldPattern);
      return (
        <ModalText key={index}>
          {parts.map((part, i) => 
            i % 2 === 0 ? part : <strong key={i}>{part}</strong>
          )}
        </ModalText>
      );
    });
  };

  return (
    <ServicesSection id="services">
      <ServicesTitle>Our Services</ServicesTitle>
      <TitleUnderline />
      <ServiceGrid>
        {services.map((service, index) => (
          <ServiceCard key={index}>
            <ServiceImage src={service.image} alt={service.title} />
            <ServiceContent>
              <ServiceTitle>{service.title}</ServiceTitle>
              <ServiceDescription>{service.description}</ServiceDescription>
              <ViewMoreButton onClick={() => setSelectedService(service)}>
                View More
              </ViewMoreButton>
            </ServiceContent>
          </ServiceCard>
        ))}
      </ServiceGrid>
      {selectedService && (
        <ModalOverlay onClick={() => setSelectedService(null)}>
          <ModalContent onClick={(e) => e.stopPropagation()}>
            <ModalTitle>{selectedService.title}</ModalTitle>
            {formatModalContent(selectedService.fullDescription)}
            <CloseButton onClick={() => setSelectedService(null)}>Close</CloseButton>
          </ModalContent>
        </ModalOverlay>
      )}
    </ServicesSection>
  );
}

export default Services;