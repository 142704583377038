export const portfolioItems = [
    {
      id: "mukherjee-mansion",
      src: "https://res.cloudinary.com/dwbiisgio/image/upload/v1726737927/rz85qfd3htnalp1wbcys.jpg",
      alt: "Luxury Living Room",
      title: "Mukherjee Mansion",
      location: "Kalyanpur Housing, Asansol",
      description: "A luxurious 4,000 sq ft residence that combines modern aesthetics with traditional Bengali architecture. This project showcases our ability to blend cultural elements with contemporary design principles.",
      gallery: [
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302222/IMG-20241030-WA0017_jcv06b.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302221/IMG-20241030-WA0016_c1x59z.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302220/IMG-20231016-WA0007_j2cfp8.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302219/IMG-20230729-WA0013_uwwfdf.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302218/IMG-20230706-WA0005_afaujs.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302216/IMG-20230509-WA0006_ywpzcr.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302215/Image71_uuv0yz.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302215/IMG_20240902_222430_qdvmie.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302215/IMG-20230404-WA0014_w3sbqr.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302211/1_kalyanpur_housing-KM-BEDROOM-02_9_24-2_psi0ry.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302211/Image33_No_lights_feaapi.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302210/1_kalyanpur_housing-KM-BEDROOM-02_9_24-3_tim2ee.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302210/Image27_fonxjf.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302209/1_kalyanpur_housing-KM-BEDROOM-02_9_24-1-1_wrphvx.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302209/Image19_asg1dp.jpg",
            "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302208/1_kalyanpur_housing-KM-28_8_24-2_d6upx0.jpg",

    ]
    },
    {
      id: "victor-residence",
      src: "https://res.cloudinary.com/dwbiisgio/image/upload/v1726737927/dly6e3e1ip876qhsqzvr.jpg",
      alt: "Elegant Bathroom",
      title: "Mr. & Mrs. Victor",
      location: "Sristinagar, Asansol",
      description: "A contemporary 3,200 sq ft apartment that emphasizes open spaces and natural light. The design focuses on creating a perfect balance between functionality and aesthetic appeal.",
      gallery: [
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1726737927/dly6e3e1ip876qhsqzvr.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299436/GROUND_FLOOR_WASHROOM_-_1_galvu8.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299438/OP-1-24.09.24-1_fairaf.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299423/1-3_cxuwiu.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299421/1-4_hajpze.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299429/3-1_s2czxj.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299433/BEDROOM-2_WASHROOM_-_2_fqzyhe.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299951/GROUND_FLOOR_WASHROOM_-_2_gkba2n.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299951/G.F._SITTING-2_wkiake.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730299950/3d-Unnamed-20241021-210018_yvtxm1.jpg"

      ]
    },
    {
      id: "punya-bhoomi",
      src: "https://res.cloudinary.com/dwbiisgio/image/upload/v1730296773/3-2_1_igbg4t.jpg",
      alt: "Serene Bedroom",
      title: "Punya Bhoomi",
      location: "Kulti, Asansol",
      description: "An elegant 5,500 sq ft bungalow that showcases the perfect harmony between indoor and outdoor living. The design incorporates sustainable elements while maintaining a luxurious appeal.",
      gallery: [
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302609/visiting_area2_rzbw3f.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302608/3-2_umlwlp.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302608/4-2_iv0gmr.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302608/visiting_area1_xv56bl.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302608/2-2_hcfcgr.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730302607/1_1_kxvdic.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730303040/IMG-20241010-WA0002_1_xfvq3a.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730303198/Screenshot_20241030_210652_Gallery_1_uzmywz.jpg",
        "https://res.cloudinary.com/dwbiisgio/image/upload/v1730303186/IMG-20241030-WA0019_1_qcyagz.jpg"
    ]
    }
  ];