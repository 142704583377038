import React from 'react';
import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { portfolioItems } from '../data/portfolioData';

const PortfolioSection = styled.section`
  text-align: center;
`;

const PortfolioTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 2rem;
`;

const PortfolioGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
`;

const PortfolioItemContainer = styled(Link)`
  position: relative;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
`;

const PortfolioItem = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
  color: white;
  padding: 1rem;

  ${PortfolioItemContainer}:hover & {
    opacity: 1;
  }

  ${PortfolioItemContainer}:hover ${PortfolioItem} {
    transform: scale(1.05);
  }
`;

const Title = styled.h3`
  font-style: italic;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  font-family: ${props => props.theme.fonts.heading};
`;

const LocationWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
`;

function Portfolio() {
  return (
    <PortfolioSection id="portfolio">
      <PortfolioTitle>Featured Projects</PortfolioTitle>
      <PortfolioGrid>
        {portfolioItems.map((item) => (
          <PortfolioItemContainer key={item.id} to={`/portfolio/${item.id}`}>
            <PortfolioItem src={item.src} alt={item.alt} />
            <Overlay>
              <Title>{item.title}</Title>
              <LocationWrapper>
                <FaMapMarkerAlt />
                {item.location}
              </LocationWrapper>
            </Overlay>
          </PortfolioItemContainer>
        ))}
      </PortfolioGrid>
    </PortfolioSection>
  );
}

export default Portfolio;