import React from 'react';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './globalStyles';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Services from './components/Services';
import Portfolio from './components/Portfolio';
import PortfolioDetails from './components/PortfolioDetails';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';

const theme = {
  colors: {
    primary: '#7c9885',
    secondary: '#5e7a6a',
    text: '#333',
    background: '#faf9f7',
    accent: '#d6ad60',
  },
  fonts: {
    main: "'Raleway', sans-serif",
    heading: "'Playfair Display', serif",
  },
};

function App() {
  return (
    <Router>
      <ThemeProvider theme={theme}>
        <Helmet>
          <title>Elegant-E-Space | Luxury Interior Design & Architecture</title>
          <meta name="description" content="Elegant-E-Space creates relaxed modern interiors that are elevated, curated, and storied. Transform your space with our luxury interior design services." />
          
          {/* Open Graph / Facebook */}
          <meta property="og:type" content="website" />
          <meta property="og:title" content="Elegant-E-Space | Luxury Interior Design" />
          <meta property="og:description" content="Elevate your space with curated luxury interior design and architectural solutions by Elegant-E-Space." />
          <meta property="og:image" content="https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/hueqo6wna0xufekjesvi.jpg" />
          <meta property="og:url" content="https://www.elegantespace.co.in" />
          
          {/* Twitter */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content="Elegant-E-Space | Luxury Interior Design" />
          <meta name="twitter:description" content="Elevate your space with curated luxury interior design and architectural solutions by Elegant-E-Space." />
          <meta name="twitter:image" content="https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/hueqo6wna0xufekjesvi.jpg" />
          
          {/* Additional SEO metadata */}
          <meta name="keywords" content="interior design, luxury interiors, architecture, home design, Asansol, West Bengal" />
          <meta name="author" content="Elegant-E-Space" />
          <link rel="canonical" href="https://www.elegantespace.co.in" />
          
          {/* Schema.org structured data */}
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "LocalBusiness",
                "name": "Elegant-E-Space",
                "image": "https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/lbygznr61vxgmxolnjbn.jpg",
                "description": "Luxury interior design and architectural solutions in Asansol, West Bengal",
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "Ananya Shopping Complex, Senraleigh Road, opp. Shristi Nagar Road",
                  "addressLocality": "Asansol",
                  "addressRegion": "West Bengal",
                  "postalCode": "713305",
                  "addressCountry": "IN"
                },
                "geo": {
                  "@type": "GeoCoordinates",
                  "latitude": 23.7007926,
                  "longitude": 86.9513959
                },
                "telephone": "+91 9382139090",
                "email": "elegantespace04@gmail.com",
                "url": "https://www.elegantespace.co.in",
                "openingHours": "Mo-Sa 09:00-18:00"
              }
            `}
          </script>
        </Helmet>
        <GlobalStyle />
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <About />
              <Services />
              <Portfolio />
              <Team />
              <Contact />
            </>
          } />
          <Route path="/portfolio/:id" element={<PortfolioDetails />} />
        </Routes>
        <Footer />
      </ThemeProvider>
    </Router>
  );
}

export default App;