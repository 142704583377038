import React, { useState } from 'react';
import styled from 'styled-components';
import { FaInstagram, FaPinterest, FaFacebook, FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';

const FooterWrapper = styled.footer`
  background-color: ${props => props.theme.colors.text};
  color: ${props => props.theme.colors.background};
  padding: 4rem 5%;
`;

const FooterContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const FooterSection = styled.div``;

const FooterTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: ${props => props.theme.colors.primary};
`;

const ContactInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
`;

const ContactIcon = styled.span`
  margin-right: 0.5rem;
  color: ${props => props.theme.colors.primary};
`;

const SocialLinks = styled.div`
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
`;

const SocialIcon = styled.a`
  color: ${props => props.theme.colors.background};
  font-size: 1.5rem;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const NewsletterInput = styled.input`
  padding: 0.5rem;
  border: none;
  border-radius: 5px;
`;

const NewsletterButton = styled.button`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
  }
`;

const MapContainer = styled.div`
  width: 100%;
  height: 200px;
  margin-top: 1rem;
`;

const Copyright = styled.p`
  text-align: center;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #555;
`;

function Footer() {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle newsletter signup here
    console.log('Newsletter signup:', email);
    setEmail('');
  };

  return (
    <FooterWrapper>
      <FooterContent>
        <FooterSection>
          <FooterTitle>Elegant-E-Space</FooterTitle>
          <ContactInfo>
            <ContactIcon><FaMapMarkerAlt /></ContactIcon>
            <p>Ananya Shopping Complex, Senraleigh Road, opp. Shristi Nagar Road, Shristinagar, Asansol, West Bengal 713305</p>
          </ContactInfo>
          <ContactInfo>
            <ContactIcon><FaPhone /></ContactIcon>
            <p>(+91) 9382139090</p>
          </ContactInfo>
          <ContactInfo>
            <ContactIcon><FaEnvelope /></ContactIcon>
            <p>elegantespace04@gmail.com</p>
          </ContactInfo>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Follow Us</FooterTitle>
          <SocialLinks>
            <SocialIcon href="#"><FaInstagram /></SocialIcon>
            <SocialIcon href="#"><FaPinterest /></SocialIcon>
            <SocialIcon href="#"><FaFacebook /></SocialIcon>
          </SocialLinks>
          <MapContainer>
            <iframe 
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3657.1021492692814!2d86.95139590000001!3d23.7007926!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39f71f28c3902643%3A0x9395fdc6897fdcde!2sElegant-E-Space!5e0!3m2!1sen!2sin!4v1695946234567!5m2!1sen!2sin"
              width="100%" 
              height="100%" 
              style={{border:0}} 
              allowFullScreen="" 
              loading="lazy" 
              referrerPolicy="no-referrer-when-downgrade">
            </iframe>
          </MapContainer>
        </FooterSection>
        <FooterSection>
          <FooterTitle>Join Our Mailing List</FooterTitle>
          <NewsletterForm onSubmit={handleSubmit}>
            <NewsletterInput 
              type="email" 
              placeholder="Your email address" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
            <NewsletterButton type="submit">Subscribe</NewsletterButton>
          </NewsletterForm>
        </FooterSection>
      </FooterContent>
    </FooterWrapper>
  );
}

export default Footer;