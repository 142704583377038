// Header.js
import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'; // Import Helmet

const HeaderWrapper = styled.header`
  background-color: ${props => props.theme.colors.background};
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 5%;
  max-width: 1200px;
  margin: 0 auto;
`;

const Logo = styled.img`
  height: 50px;
`;

const NavList = styled.ul`
  display: flex;
  list-style: none;

  @media (max-width: 768px) {
    display: none;
  }
`;

const NavItem = styled.li`
  margin-left: 2rem;
`;

const NavLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  font-weight: 600;
  transition: color 0.3s ease;

  &:hover {
    color: ${props => props.theme.colors.primary};
  }
`;

const CTAButton = styled(NavLink)`
  background-color: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.background};
  padding: 0.5rem 1rem;
  border-radius: 5px;

  &:hover {
    background-color: ${props => props.theme.colors.secondary};
    color: ${props => props.theme.colors.background};
  }
`;

function Header() {
  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "LocalBusiness",
              "name": "Elegant-E-Space",
              "address": {
                "@type": "PostalAddress",
                "streetAddress": "Ananya Shopping Complex, Asansol",
                "addressRegion": "West Bengal",
                "postalCode": "713305",
                "addressCountry": "IN"
              },
              "telephone": "+91 9382139090",
              "email": "elegantespace04@gmail.com"
            }
          `}
        </script>
        <meta property="og:title" content="Elegant-E-Space | Interior Design & Architecture" />
        <meta property="og:description" content="Elevate your space with Elegant-E-Space's interior design and real estate solutions." />
        <meta property="og:image" content="https://example.com/hero-image.jpg" />
        <meta property="og:url" content="https://example.com" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <HeaderWrapper>
        <Nav>
          <Logo src='https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/lbygznr61vxgmxolnjbn.jpg' alt="Urbanology Designs Logo" />
          <NavList>
            <NavItem><NavLink href="#about">About</NavLink></NavItem>
            <NavItem><NavLink href="#services">Services</NavLink></NavItem>
            <NavItem><NavLink href="#portfolio">Portfolio</NavLink></NavItem>
            <NavItem><NavLink href="#team">Team</NavLink></NavItem>
            <NavItem><CTAButton href="#contact">Contact</CTAButton></NavItem>
          </NavList>
        </Nav>
      </HeaderWrapper>
    </>
  );
}

export default Header;
