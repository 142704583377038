import React from 'react';
import styled from 'styled-components';

const AboutSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const AboutContent = styled.div`
  max-width: 50%;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-bottom: 2rem;
  }
`;

const AboutTitle = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const AboutText = styled.p`
  margin-bottom: 1rem;
`;

const AboutImage = styled.img`
  width: 40%;
  border-radius: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

function About() {
  return (
    <AboutSection id="about">
      <AboutContent>
        <AboutTitle>Who We Are</AboutTitle>
        <AboutText>
          Elegant-E-Space creates relaxed modern interiors that are elevated,
          curated, and storied. Rooted in authenticity, grounded in luxury, and a
          dedicated departure from the ordinary.
        </AboutText>
        <AboutText>
          Our design-forward ethos drives us to look beyond today's trends and
          whims to create homes with longevity and lasting appeal that embody
          comfort and character.
        </AboutText>
      </AboutContent>
      <AboutImage 
        src="https://res.cloudinary.com/dwbiisgio/image/upload/v1726737929/kzb0upwv6ygqufyiucux.jpg" 
        alt="Urbanology Designs Studio" 
      />
    </AboutSection>
  );
}

export default About;