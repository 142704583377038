import React from 'react';
import styled from 'styled-components';

const TeamSection = styled.section`
  padding: 4rem 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f9f9f9;
`;

const FounderCard = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 900px;
  margin: 2rem 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  overflow: hidden;
  background-color: white;

  @media (min-width: 768px) {
    flex-direction: row;
  }
`;

const FounderImageContainer = styled.div`
  width: 100%;
  padding: 2rem;

  @media (min-width: 768px) {
    width: 40%;
  }
`;

const FounderImage = styled.img`
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto;
  display: block;
  border: 5px solid #f0f0f0;
`;

const FounderText = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  font-family: 'Georgia', serif;
  text-align: justify;
  color: #333;

  @media (min-width: 768px) {
    width: 60%;
  }
`;

const FounderHeader = styled.h2`
  font-family: 'Playfair Display', serif;
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: left;
  color: #2c3e50;
  font-weight: bold;
  position: relative;
  padding-bottom: 15px;

  &::after {
    content: '';
    display: block;
    width: calc(100% + 2rem); // Extend to the right edge of padding
    height: 3px;
    background-color: #9a8c98;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const FounderQuote = styled.p`
  margin-bottom: 1rem;
  line-height: 1.8;
`;

const FounderSignature = styled.p`
  text-align: right;
  margin-top: 1.5rem;
  font-style: normal;
  font-weight: bold;
  color: #2c3e50;
`;

function Team() {
  return (
    <TeamSection id="team">
      <FounderCard>
        <FounderImageContainer>
          <FounderImage
            src="https://res.cloudinary.com/dwbiisgio/image/upload/v1729286675/WhatsApp_Image_2024-10-17_at_09.44.13_d918e28b_xlwzph.jpg"
            alt="Founder Image"
          />
        </FounderImageContainer>
        <FounderText>
          <FounderHeader>From The Founder</FounderHeader>
          <FounderQuote>
            "I feel very grateful and happy to welcome you to the world of
            Elegante Space where we are providing you with high-end Interior
            designing, Architecture, and Construction services of your dream
            home along with the fine taste of Premium Real Estate.
          </FounderQuote>
          <FounderQuote>
            Your wait is finally over. We have immensely invested for your
            satisfaction and comfort and will continue to do so through thick
            and thin."
          </FounderQuote>
          <FounderSignature>
            - <strong>Kaustav Mukherjee</strong>
          </FounderSignature>
        </FounderText>
      </FounderCard>
    </TeamSection>
  );
}

export default Team;