import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    font-family: ${props => props.theme.fonts.main};
    line-height: 1.6;
    color: ${props => props.theme.colors.text};
  }

  h1, h2, h3 {
    font-family: ${props => props.theme.fonts.heading};
  }

  section {
    padding: 5rem 5%;
    max-width: 1200px;
    margin: 0 auto;
  }
`;

export default GlobalStyle;