import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { portfolioItems } from '../data/portfolioData';

const DetailsContainer = styled.div`
  padding-top: 80px;
`;

const Header = styled.header`
  background: ${props => props.theme.colors.text};
  color: white;
  padding: 4rem 5%;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin-bottom: 1rem;
  font-style: italic;
`;

const Location = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 2rem;
`;

const Description = styled.p`
  max-width: 800px;
  margin: 0 auto;
  font-size: 1.1rem;
  line-height: 1.8;
`;

const Gallery = styled.div`
  padding: 4rem 5%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

const GalleryImage = styled.img`
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }
`;

function PortfolioDetails() {
  const { id } = useParams();
  const project = portfolioItems.find(item => item.id === id);

  if (!project) return <div>Project not found</div>;

  return (
    <DetailsContainer>
      <Header>
        <Title>{project.title}</Title>
        <Location>
          <FaMapMarkerAlt />
          {project.location}
        </Location>
        <Description>{project.description}</Description>
      </Header>
      <Gallery>
        {project.gallery.map((image, index) => (
          <GalleryImage key={index} src={image} alt={`${project.title} - Image ${index + 1}`} />
        ))}
      </Gallery>
    </DetailsContainer>
  );
}

export default PortfolioDetails;